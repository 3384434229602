@import 'styles/includes.scss';

.SearchInputForm {
    position: relative;
    width: 100%;

    &__Input {
        width: 100%;
        padding: 1.3rem 2.4rem;
        border-radius: 50px;
        border: none;
        @extend %body-2;
    }

    &__ButtonWrapper {
        position: absolute;
        top: 50%;
        right: 2px;
        transform: translateY(-50%);
        display: flex;
    }

    &__Reset {
        display: block;
        background: none;
        border: none;
        padding: 0 1.6rem;
    }

    &__Button {
        padding: 1.2rem 1.2rem;
        background-color: $colorPink;
        color: $colorWhite;
        border-radius: 50px;
        display: flex;
        align-items: center;
        border: 1px solid white;

        @include media(m){
            padding: 1.2rem 2rem;
        }
    }

    &__ButtonText {
        display: none;
        font-family: $fontBrand;
        font-size: 1.6rem;
        letter-spacing: .3px;
        margin-right: .8rem;

        @include media(m){
            display: block;
        }
    }
}
